import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _5e8739c8 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _30e40546 = () => interopDefault(import('../pages/dashboard.vue' /* webpackChunkName: "pages/dashboard" */))
const _68efcbc7 = () => interopDefault(import('../pages/e-learning/index.vue' /* webpackChunkName: "pages/e-learning/index" */))
const _2331832e = () => interopDefault(import('../pages/for-distributors.vue' /* webpackChunkName: "pages/for-distributors" */))
const _85cadc0c = () => interopDefault(import('../pages/forgot-password/index.vue' /* webpackChunkName: "pages/forgot-password/index" */))
const _17d25e09 = () => interopDefault(import('../pages/get-your-box/index.vue' /* webpackChunkName: "pages/get-your-box/index" */))
const _7063a02b = () => interopDefault(import('../pages/impersonate.vue' /* webpackChunkName: "pages/impersonate" */))
const _77d4e102 = () => interopDefault(import('../pages/pickup-request/index.vue' /* webpackChunkName: "pages/pickup-request/index" */))
const _444a00a1 = () => interopDefault(import('../pages/product.vue' /* webpackChunkName: "pages/product" */))
const _b06c5f4a = () => interopDefault(import('../pages/profile.vue' /* webpackChunkName: "pages/profile" */))
const _050fe7ba = () => interopDefault(import('../pages/registration/index.vue' /* webpackChunkName: "pages/registration/index" */))
const _023343e7 = () => interopDefault(import('../pages/sign-in.vue' /* webpackChunkName: "pages/sign-in" */))
const _564eda56 = () => interopDefault(import('../pages/user-deleted.vue' /* webpackChunkName: "pages/user-deleted" */))
const _ceea2e1c = () => interopDefault(import('../pages/vessels/index.vue' /* webpackChunkName: "pages/vessels/index" */))
const _cfe575c2 = () => interopDefault(import('../pages/webinar/index.vue' /* webpackChunkName: "pages/webinar/index" */))
const _4d309d8c = () => interopDefault(import('../pages/documents/privacy-policy.vue' /* webpackChunkName: "pages/documents/privacy-policy" */))
const _52dabba9 = () => interopDefault(import('../pages/documents/terms-and-conditions.vue' /* webpackChunkName: "pages/documents/terms-and-conditions" */))
const _3b6144bb = () => interopDefault(import('../pages/email/thank-you.vue' /* webpackChunkName: "pages/email/thank-you" */))
const _90b0bb4a = () => interopDefault(import('../pages/registration/finish.vue' /* webpackChunkName: "pages/registration/finish" */))
const _77e048e7 = () => interopDefault(import('../pages/registration/personal-information.vue' /* webpackChunkName: "pages/registration/personal-information" */))
const _16fcaaa8 = () => interopDefault(import('../pages/registration/why-do-you-sign-up.vue' /* webpackChunkName: "pages/registration/why-do-you-sign-up" */))
const _5628193f = () => interopDefault(import('../pages/vessels/add-new-vessel.vue' /* webpackChunkName: "pages/vessels/add-new-vessel" */))
const _f7541cd4 = () => interopDefault(import('../pages/webinar/finish.vue' /* webpackChunkName: "pages/webinar/finish" */))
const _476a38c4 = () => interopDefault(import('../pages/course/join/success.vue' /* webpackChunkName: "pages/course/join/success" */))
const _1bdfdcf8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _73711e5e = () => interopDefault(import('../pages/course/join/_uuid/index.vue' /* webpackChunkName: "pages/course/join/_uuid/index" */))
const _5823af66 = () => interopDefault(import('../pages/forgot-password/form/_token.vue' /* webpackChunkName: "pages/forgot-password/form/_token" */))
const _575dab38 = () => interopDefault(import('../pages/registration/verify/_token.vue' /* webpackChunkName: "pages/registration/verify/_token" */))
const _9d751514 = () => interopDefault(import('../pages/course/join/_uuid/connect.vue' /* webpackChunkName: "pages/course/join/_uuid/connect" */))
const _013f1861 = () => interopDefault(import('../pages/product-details/_uuid.vue' /* webpackChunkName: "pages/product-details/_uuid" */))
const _106eaa9d = () => interopDefault(import('../pages/register-vessel/_uuid.vue' /* webpackChunkName: "pages/register-vessel/_uuid" */))
const _7c174c4b = () => interopDefault(import('../pages/vessel-results/_id/index.vue' /* webpackChunkName: "pages/vessel-results/_id/index" */))
const _2fa4126c = () => interopDefault(import('../pages/quiz/_quiz_id/result.vue' /* webpackChunkName: "pages/quiz/_quiz_id/result" */))
const _479f0676 = () => interopDefault(import('../pages/quiz/_quiz_id/start.vue' /* webpackChunkName: "pages/quiz/_quiz_id/start" */))
const _23f33cc1 = () => interopDefault(import('../pages/vessel-results/_id/3d-image-of-your-vessel.vue' /* webpackChunkName: "pages/vessel-results/_id/3d-image-of-your-vessel" */))
const _85b376f8 = () => interopDefault(import('../pages/vessel-results/_id/clinical-results-of-your-vessel.vue' /* webpackChunkName: "pages/vessel-results/_id/clinical-results-of-your-vessel" */))
const _7ffbc520 = () => interopDefault(import('../pages/vessel-results/_id/community-evaluation-of-your-vessel.vue' /* webpackChunkName: "pages/vessel-results/_id/community-evaluation-of-your-vessel" */))
const _492fe97b = () => interopDefault(import('../pages/vessel-results/_id/details.vue' /* webpackChunkName: "pages/vessel-results/_id/details" */))
const _a07dba02 = () => interopDefault(import('../pages/vessel-results/_id/expert-evaluation-of-your-vessel.vue' /* webpackChunkName: "pages/vessel-results/_id/expert-evaluation-of-your-vessel" */))
const _485c8066 = () => interopDefault(import('../pages/vessel-results/_id/give-a-feedback.vue' /* webpackChunkName: "pages/vessel-results/_id/give-a-feedback" */))
const _356e22cc = () => interopDefault(import('../pages/vessel-results/_id/score-of-your-vessel.vue' /* webpackChunkName: "pages/vessel-results/_id/score-of-your-vessel" */))
const _11bb9b0e = () => interopDefault(import('../pages/vessel-results/_id/steps-summary.vue' /* webpackChunkName: "pages/vessel-results/_id/steps-summary" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _5e8739c8,
    name: "about-us"
  }, {
    path: "/dashboard",
    component: _30e40546,
    name: "dashboard"
  }, {
    path: "/e-learning",
    component: _68efcbc7,
    name: "e-learning"
  }, {
    path: "/for-distributors",
    component: _2331832e,
    name: "for-distributors"
  }, {
    path: "/forgot-password",
    component: _85cadc0c,
    name: "forgot-password"
  }, {
    path: "/get-your-box",
    component: _17d25e09,
    name: "get-your-box"
  }, {
    path: "/impersonate",
    component: _7063a02b,
    name: "impersonate"
  }, {
    path: "/pickup-request",
    component: _77d4e102,
    name: "pickup-request"
  }, {
    path: "/product",
    component: _444a00a1,
    name: "product"
  }, {
    path: "/profile",
    component: _b06c5f4a,
    name: "profile"
  }, {
    path: "/registration",
    component: _050fe7ba,
    name: "registration"
  }, {
    path: "/sign-in",
    component: _023343e7,
    name: "sign-in"
  }, {
    path: "/user-deleted",
    component: _564eda56,
    name: "user-deleted"
  }, {
    path: "/vessels",
    component: _ceea2e1c,
    name: "vessels"
  }, {
    path: "/webinar",
    component: _cfe575c2,
    name: "webinar"
  }, {
    path: "/documents/privacy-policy",
    component: _4d309d8c,
    name: "documents-privacy-policy"
  }, {
    path: "/documents/terms-and-conditions",
    component: _52dabba9,
    name: "documents-terms-and-conditions"
  }, {
    path: "/email/thank-you",
    component: _3b6144bb,
    name: "email-thank-you"
  }, {
    path: "/registration/finish",
    component: _90b0bb4a,
    name: "registration-finish"
  }, {
    path: "/registration/personal-information",
    component: _77e048e7,
    name: "registration-personal-information"
  }, {
    path: "/registration/why-do-you-sign-up",
    component: _16fcaaa8,
    name: "registration-why-do-you-sign-up"
  }, {
    path: "/vessels/add-new-vessel",
    component: _5628193f,
    name: "vessels-add-new-vessel"
  }, {
    path: "/webinar/finish",
    component: _f7541cd4,
    name: "webinar-finish"
  }, {
    path: "/course/join/success",
    component: _476a38c4,
    name: "course-join-success"
  }, {
    path: "/",
    component: _1bdfdcf8,
    name: "index"
  }, {
    path: "/course/join/:uuid",
    component: _73711e5e,
    name: "course-join-uuid"
  }, {
    path: "/forgot-password/form/:token",
    component: _5823af66,
    name: "forgot-password-form-token"
  }, {
    path: "/registration/verify/:token?",
    component: _575dab38,
    name: "registration-verify-token"
  }, {
    path: "/course/join/:uuid?/connect",
    component: _9d751514,
    name: "course-join-uuid-connect"
  }, {
    path: "/product-details/:uuid?",
    component: _013f1861,
    name: "product-details-uuid"
  }, {
    path: "/register-vessel/:uuid?",
    component: _106eaa9d,
    name: "register-vessel-uuid"
  }, {
    path: "/vessel-results/:id",
    component: _7c174c4b,
    name: "vessel-results-id"
  }, {
    path: "/quiz/:quiz_id?/result",
    component: _2fa4126c,
    name: "quiz-quiz_id-result"
  }, {
    path: "/quiz/:quiz_id?/start",
    component: _479f0676,
    name: "quiz-quiz_id-start"
  }, {
    path: "/vessel-results/:id?/3d-image-of-your-vessel",
    component: _23f33cc1,
    name: "vessel-results-id-3d-image-of-your-vessel"
  }, {
    path: "/vessel-results/:id?/clinical-results-of-your-vessel",
    component: _85b376f8,
    name: "vessel-results-id-clinical-results-of-your-vessel"
  }, {
    path: "/vessel-results/:id?/community-evaluation-of-your-vessel",
    component: _7ffbc520,
    name: "vessel-results-id-community-evaluation-of-your-vessel"
  }, {
    path: "/vessel-results/:id?/details",
    component: _492fe97b,
    name: "vessel-results-id-details"
  }, {
    path: "/vessel-results/:id?/expert-evaluation-of-your-vessel",
    component: _a07dba02,
    name: "vessel-results-id-expert-evaluation-of-your-vessel"
  }, {
    path: "/vessel-results/:id?/give-a-feedback",
    component: _485c8066,
    name: "vessel-results-id-give-a-feedback"
  }, {
    path: "/vessel-results/:id?/score-of-your-vessel",
    component: _356e22cc,
    name: "vessel-results-id-score-of-your-vessel"
  }, {
    path: "/vessel-results/:id?/steps-summary",
    component: _11bb9b0e,
    name: "vessel-results-id-steps-summary"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
